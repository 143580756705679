import React from "react";
import theme from "theme";
import { Theme, Text, Box, Strong, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Піцерія Bella Gusto
			</title>
			<meta name={"description"} content={"Пориньте в наш світ ароматної піци, випеченої до досконалості, і дозвольте своїм смаковим рецепторам вирушити в кулінарну подорож, яка не має собі рівних за смаком і свіжістю."} />
			<meta property={"og:title"} content={"Піцерія Bella Gusto"} />
			<meta property={"og:description"} content={"Пориньте в наш світ ароматної піци, випеченої до досконалості, і дозвольте своїм смаковим рецепторам вирушити в кулінарну подорож, яка не має собі рівних за смаком і свіжістю."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6666e55e1d712a002334ca52/images/2-2.jpg?v=2024-06-10T11:53:27.175Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-10">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Text margin="0px 0px 15px 0px" font="normal 700 42px/1.2 --fontFamily-sans" text-align="center">
				Огляд меню
			</Text>
			<Text margin="0px 0px 50px 0px" font="--lead" color="#626970" text-align="center">
				Тут кожна страва розповідає історію смаку, створена з точністю та глибокою повагою до італійських кулінарних традицій. Наше меню розроблено таким чином, щоб задовольнити будь-який смак: від любителя піци, який шукає класичного досвіду, до авантюрного їдця, який шукає нових поєднань.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="24px"
				lg-grid-gap="25px 25px"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1600028068383-ea11a7a101f3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000) center/cover repeat scroll padding-box"
					height="250px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						align-items="center"
						margin="0px 0px 10px 0px"
						lg-margin="0px 0px 15px 0px"
					/>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							<Strong>
								Класичні піци
							</Strong>
						</Text>
						<Text
							font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
							color="--lightD2"
							margin="10px 0 0 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Маргарита:
							</Strong>
							{" "}Свіжа моцарела, базилік, помідори.{"\n"}
							<br />
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Пепероні:
							</Strong>
							{" "}Пепероні.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1534308983496-4fabb1a015ee?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							<Strong>
								Для гурманів
							</Strong>
						</Text>
						<Text
							font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
							color="--lightD2"
							margin="10px 0 0 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
						>
							<Strong>
								Трюфельна з грибами:
							</Strong>
							{" "}Гриби, трюфельна олія.{"\n"}
							<br />
							<Strong>
								BBQ з куркою:
							</Strong>
							{" "}BBQ соус, курка, червона цибуля.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1617343251257-b5d709934ddd?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							<Strong>
								Вегетаріанська піца
							</Strong>
						</Text>
						<Text
							font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
							color="--lightD2"
							margin="10px 0 0 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
						>
							<Strong>
								Веггі Суприм:
							</Strong>
							{" "}Перець, цибуля, гриби, оливки.{"\n"}
							<br />
							<Strong>
								Шпинат і фета:
							</Strong>
							{" "}Фета, шпинат.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1563379926898-05f4575a45d8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							<Strong>
								Крім піци
							</Strong>
						</Text>
						<Text
							font="normal 400 17px/1.5 --fontFamily-sansHelvetica"
							color="--lightD2"
							margin="10px 0 0 0px"
							border-color="--color-lightD2"
							sm-margin="0 10px 0 0px"
						>
							<Strong>
								Закуски:
							</Strong>
							{" "}Почніть свою трапезу з нашого часникового хліба або свіжого салату капрезе.{"\n"}
							<br />
							<Strong>
								Паста:
							</Strong>
							{" "}Досліджуйте наші пасти, від ситної лазаньї до ніжної лінгвіні з морепродуктами.
							<br />
							{"\n"}
							<Strong>
								Десерти:
							</Strong>
							{" "}Жодна трапеза не обходиться без солодкого акценту. Спробуйте наше тірамісу або домашнє джелато.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text">
				<Strong>
					Ознайомтеся з усіма нашими пропозиціями
				</Strong>
				{"\n"}
			</Override>
			<Override slot="text1">
				Цей огляд - лише частинка того, що Bella Gusto Pizzeria має у своєму меню. Ми запрошуємо вас відвідати нас і дослідити весь асортимент наших страв. Кожна страва готується з турботою, щоб кожен гість міг знайти щось для задоволення своїх бажань і дієтичних потреб.
			</Override>
		</Components.Cta>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6666e55e1d712a002334ca50"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});